import React from 'react'
import {connect} from "react-redux";
import AuthenticationAction from "../stores/authentication/AuthenticationAction";
import PaymentAction from "../stores/payment/PaymentAction";
import RentalAction from "../stores/rental/RentalAction";
import Transaction from "./Payment";

import 'moment/locale/en-gb'
import 'moment/locale/de'
import 'moment/locale/nl'
import moment from 'moment';


import MutationAction from "../stores/mutation/MutationAction";
import LanguageAction from "../stores/language/LanguageAction";
import Result from "./Result";
import Processing from "./Processing";

import {Route, Switch, useParams} from "react-router";
import queryString from 'query-string';

const mapStateToProps = (state) => ({
    payment: state.payment,
    authentication: state.authentication,
    language: state.language,
    rental: state.rental
});

class App extends React.Component {


    componentDidMount() {
        this.props.dispatch(AuthenticationAction.requestAccessToken()).then(() => {
            this.props.dispatch(LanguageAction.default()).then(() => {
                this._init();
            })
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match && this.props.match.params && prevProps.match.params.rentalUuid !== this.props.match.params.rentalUuid) {
            this._init();
        }
    }

    _init() {
        if (this.props.match && this.props.match.params) {
            const rentalUuid = this.props.match.params.rentalUuid

            const getResult = new URLSearchParams(window.location.search);

            const deposit = (getResult.get('deposit') === 'false' || getResult.get('deposit') === '0') ? 'false' : 'true'

            if (rentalUuid) {
                this.props.dispatch(PaymentAction.apiGet(rentalUuid))
                this.props.dispatch(PaymentAction.apiStatus(rentalUuid, deposit))
                this.props.dispatch(RentalAction.apiGetDetail(rentalUuid, deposit))
                this.props.dispatch(MutationAction.apiGetList(rentalUuid))
            }
        }
    }

    render() {

        moment().locale('de');

        if (this.props.payment.data && this.props.rental.data && this.props.payment.data.items.transaction.partner) {

            const {logo} = this.props.payment.data.items.transaction.partner


            return (
                <div>

                    <header className="c-header">
                        <nav className="c-header__primary o-container o-container--secondary"
                             style={{padding: '3rem 0'}}>
                            {logo &&
                            <img className="c-header__logo"
                                 src={logo} alt="logo"/>
                            }
                            <div className={'c-lang-selector'}>
                                <img src={'/images/' + this.props.language.active + '.png'} style={{height: '2rem'}}
                                     alt={'country flag'}/>
                                <i className="icon icon-arrow-down"/>
                                <select onChange={(e) => this._changeLanguage(e)}>
                                    {this.props.language.options.map((language) => {

                                        const selected = this.props.language.active === language.key

                                        return (
                                            <option
                                                selected={selected}
                                                key={'language-' + language.key}
                                                value={language.key}>
                                                {language.value}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                        </nav>
                    </header>

                    <Switch>

                        <Route
                            path={'/:rentalUuid?/processing'}
                            component={Processing}
                            history={this.props.history}
                        />

                        <Route
                            path={'/:rentalUuid?/result'}
                            component={Result}
                            history={this.props.history}
                        />

                        <Route component={() => <DefaultView {...this.props} />}/>

                    </Switch>


                </div>
            );
        } else return (
            <div className={'temp-screen'} style={{
                background: '#eee',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>

                <img src={'/images/Tilia-logo-wit.png'} style={{height: '10rem'}}/>

            </div>
        );

    }

    _changeLanguage = (e) => {

        this.props.dispatch(LanguageAction.set(e.target.value)).then(() => {
            this._init()
        })
    }

}

const DefaultView = (props) => {

    switch (props.payment.status) {
        case('paid'):
            return <Result/>
        default:
            return <Transaction/>

    }

}


export default connect(mapStateToProps)(App);
