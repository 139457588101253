import fetchHelper from "../../helpers/fetchHelper";

export default class PaymentEffect {

    static async apiGet(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null

    }

    static async apiStatus(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response.items.transactionData.status
        } else return null

    }


    static async apiPay(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            if (response.status.code === 200) {

                window.location = response.items.transactionData['_links'].checkout.href

            } else {

                return {
                    message: response.status.message
                }

            }
        } else return null

    }


}

