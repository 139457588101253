export default class LanguageEffect {

    static async set(value, dispatch) {

        console.log(value)

        return value
    }

}

