import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './styles/main.scss';
import App from './views/App';
import {createMemoryHistory} from 'history';
import rootStore from './stores/rootStore';
import {Route, BrowserRouter} from 'react-router-dom'
import {globalConfig, globalConfigUrl} from "./configuration/config";


(async (window) => {
    fetch(globalConfigUrl)
        .then(response => response.json())
        .then((data) => {

            globalConfig.set(data);

            const initialState = {};
            const history = createMemoryHistory();
            const store = rootStore(initialState, history);

            return <Provider store={store}>
                <BrowserRouter basename={'/'}>
                    <div />
                    <Route
                        path={'/:rentalUuid?'}
                        history={history} dispatch={store.dispatch}
                        render={(props) => (
                            <App {...props} />
                        )}
                    />
                </BrowserRouter>
            </Provider>;
        })
        .catch(e => {
            return <p style={{color: "red", textAlign: "center"}}>Error while fetching global config</p>;
        })
        .then((reactElement) => {
            ReactDOM.render(
                reactElement,
                document.getElementById("root")
            );
        });


})(window);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

