import PaymentAction from "./PaymentAction";

export default class PaymentReducer {

    static initialState = {
        data: null,
        pay: null,
        status: null,
        processing: false,
    };

    static reducer(state = PaymentReducer.initialState, action) {
        switch (action.type) {

            case PaymentAction.API_GET + "_FINISHED":
                return {
                    ...state,
                    data: action.payload
                }

            case PaymentAction.API_PAY:
                return {
                    ...state,
                    processing: true
                }

            case PaymentAction.API_PAY + "_FINISHED":
                return {
                    ...state,
                    pay: action.payload,
                    processing: false
                }

            case PaymentAction.API_STATUS + "_FINISHED":
                return {
                    ...state,
                    status: action.payload
                }

            default:
                return state;
        }
    }
}

