const ApiEnum = {
    ApiAuthConnect: '/auth/connect',

    ApiPartnerUserLogin: '/partner/user/login',
    ApiPartnerUserPinCode: '/partner/user/pin-code',

    ApiClientMutationList: '/client/mutation/list',
    ApiClientPaymentGet: '/client/payment/get',
    ApiClientPaymentPay: '/client/payment/pay',
    ApiClientPaymentStatus: '/client/payment/status',
    ApiClientRentalDetail: '/client/rental/detail',
}

export default ApiEnum
