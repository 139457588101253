import ErrorAction from "../stores/error/ErrorAction";
import {globalConfig} from "../configuration/config";

const fetchHelper = (values, dispatch) => {

    if (values.method === 'post') {

        return fetch(globalConfig.get().apiUrl + values.fetchUrl, {
            method: values.method,
            headers: {
                'Authorization': 'Bearer ' + values.accessToken,
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                ...values.params
            })
        }).then((response) => {
            return response.json()
        }).then((response) => {

            if (response.status.code === 200) {

                dispatch(ErrorAction.unsetError())
                return response

            } else {
                dispatch(ErrorAction.setError(response))
            }

        }).catch(function (error) {
            dispatch(ErrorAction.catchError(error))
        })

    }else{

        return fetch(globalConfig.get().apiUrl + values.fetchUrl, {
            method: values.method,
            headers: {
                'Authorization': 'Bearer ' + values.accessToken,
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {

            if (response.status.code === 200) {

                dispatch(ErrorAction.unsetError())
                return response

            } else {
                dispatch(ErrorAction.setError(response))
            }

        }).catch(function (error) {
            dispatch(ErrorAction.catchError(error))
        })
    }

}

export default fetchHelper