import ActionUtility from '../../helpers/ActionUtility'
import PaymentEffect from "./PaymentEffect";
import ApiEnum from "../../constants/ApiEnum";

export default class PaymentAction {
    static API_GET = 'TransactionAction.API_GET';
    static API_PAY = 'TransactionAction.API_PAY';
    static API_STATUS = 'TransactionAction.API_STATUS';

    static apiGet(uuid) {
        return async (dispatch, getState) => {

            const language = getState().language.active

            const values = {
                fetchUrl: ApiEnum.ApiClientPaymentGet + '?rental=' + uuid + '&language=' + language,
                accessToken: getState().authentication.accessToken
            }

            await ActionUtility.createThunkEffect(dispatch, PaymentAction.API_GET, PaymentEffect.apiGet, values);
        };
    }

    static pay(deposit) {
        return async (dispatch, getState) => {

            const language = getState().language.active
            const uuid = getState().rental.data.items.rental.uuid

            const values = {
                fetchUrl: ApiEnum.ApiClientPaymentPay + '?rental=' + uuid + '&language=' + language,
                accessToken: getState().authentication.accessToken,
                method: 'post',
                params: {
                    redirect_url: window.location.origin + window.location.pathname + '/processing'
                }
            }

            await ActionUtility.createThunkEffect(dispatch, PaymentAction.API_PAY, PaymentEffect.apiPay, values);

        };
    }

    static apiStatus(uuid, deposit) {
        return async (dispatch, getState) => {

            const language = getState().language.active

            const values = {
                fetchUrl: ApiEnum.ApiClientPaymentStatus + '?rental=' + uuid + '&language=' + language,
                accessToken: getState().authentication.accessToken
            }

            await ActionUtility.createThunkEffect(dispatch, PaymentAction.API_STATUS, PaymentEffect.apiStatus, values);
        };
    }

}