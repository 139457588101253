import React from 'react'
import {connect} from "react-redux";
import moment from 'moment';
import CalculateDaysBetween from "../helpers/CalculateDaysBetween";
import FormatHolidayAddress from "../helpers/FormatHolidayAddress";
import DisplayItemName from "../helpers/DisplayItemName";
import DisplayPrice from "../helpers/DIsplayPrice";
import GetContrast from "../helpers/GetContrast";
import PaymentAction from "../stores/payment/PaymentAction";
import Translate from "../helpers/Translate";

const mapStateToProps = (state) => ({
    payment: state.payment,
    rental: state.rental,
    mutation: state.mutation,
    language: state.language
});


class Payment extends React.Component {


    render() {

        const getResult = new URLSearchParams(window.location.search);

        const deposit = (getResult.get('deposit') === 'false' || getResult.get('deposit') === '0') ? 'false' : 'true'

        console.log(deposit)

        const {primary_color_dark, primary_color_light} = this.props.payment.data.items.transaction.partner

        if (this.props.payment && this.props.rental.data && this.props.rental.data.items) {

            const {
                rental,
                customer,
                customer_private_person,
                customer_addresses,
                delivery_address,
                partner_location,
                customer_company,
                rentalItems,
                totalPrice,
                totalPriceRest,
                totalDeposit
            } = this.props.rental.data.items

            const days = CalculateDaysBetween(rental.date_rent_start, rental.date_rent_end)

            return (
                <div className="App">

                    {!!rental &&
                    <section className={'c-banner-payment '} style={{
                        padding: '5rem 2.5rem',
                        background: primary_color_dark,
                        color: GetContrast(primary_color_dark)
                    }}>
                        <div className={'c-banner-payment__content o-container  o-container--secondary'}>
                            <h1 style={{marginBottom: '0'}}>{Translate('Betaling voldoen', this.props.language.active)}</h1>
                            <p style={{marginTop: '0'}}>{Translate('Bestelling', this.props.language.active)} {rental.id} -

                                {customer.type === 'private' &&
                                <> {customer_private_person.initials} {customer_private_person.first_name} {customer_private_person.insertion} {customer_private_person.last_name}</>
                                }
                            </p>
                        </div>
                    </section>
                    }

                    <main className="s-webshop-order language-nl">
                        <div className="o-container o-container--secondary">
                            <div className="">
                                <header className="c-subject__header">
                                    <h2><span>{Translate('Bestellingsoverzicht', this.props.language.active)}</span>
                                    </h2>
                                </header>
                                <div className="c-order-overview">
                                    <div className="c-order-overview__half">
                                        <div className="c-order-overview__box">
                                            <header>
                                                <h3>
                                                    <span>{Translate('Verhuurperiode', this.props.language.active)}</span>
                                                </h3>
                                                <div className="btn btn--pill">
                                                    <span><span>{days} {Translate('dag(en)', this.props.language.active)}</span></span>
                                                </div>
                                            </header>
                                            <div className="c-order-overview__box__content">
                                                <div>
                                                    <span>{moment(rental.date_rent_start).locale(this.props.language.active).format('dddd D MMMM')} {Translate('t/m', this.props.language.active)} {moment(rental.date_rent_end).locale(this.props.language.active).format('dddd D MMMM')}</span>
                                                </div>
                                            </div>
                                        </div>

                                        {delivery_address &&
                                        <div className="c-order-overview__box">
                                            <header>
                                                <h3>{Translate('Uw vakantieverblijf', this.props.language.active)}</h3>
                                            </header>
                                            <div className="c-order-overview__box__content">
                                                <div>
                                                    <span>{FormatHolidayAddress(delivery_address)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        }

                                        <div className="c-order-overview__box">
                                            <header>
                                                <h3>{Translate('Leveringsoptie', this.props.language.active)}</h3>
                                            </header>
                                            <div className="c-order-overview__box__content">
                                                <div>
                                                    {!!rental.delivery &&
                                                    <span>{Translate('Bezorging op vakantieadres (tussen 08:00 - 10:00)', this.props.language.active)} - {partner_location.name}</span>
                                                    }

                                                    {!rental.delivery &&
                                                    <span>{Translate('De fietsen kunnen opgehaald worden bij', this.props.language.active)} {partner_location.name}</span>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className="c-order-overview__box">
                                            <header>
                                                <h3>{Translate('Uw gegevens', this.props.language.active)}</h3>
                                            </header>

                                            <div className="c-order-overview__box__content">
                                                <div>

                                                    {customer.type === 'private' &&
                                                    <>
                                                        <span>{customer_private_person.initials} {customer_private_person.first_name} {customer_private_person.insertion} {customer_private_person.last_name}</span>
                                                        <span>{customer_private_person.email}</span>
                                                        <span>{customer_private_person.telephone}</span>
                                                    </>
                                                    }

                                                    {customer.type === 'company' &&
                                                    <>
                                                        <span>{customer_company.name}</span>
                                                        <span>{customer_company.email}</span>
                                                        <span>{customer_company.telephone}</span>
                                                    </>
                                                    }

                                                    {customer_addresses &&
                                                    Object.values(customer_addresses)
                                                        .filter((address) => address.type.code === 'invoice')
                                                        .map((address) => {
                                                                return (
                                                                    <div key={address.id}>
                                                                        <span>{address.street} {address.house_number}{address.house_number_addition} </span>
                                                                        <span>{address.zip_code} {address.city}</span>
                                                                    </div>
                                                                );

                                                            }
                                                        )
                                                    }

                                                    <span>{rental.remarks}</span>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="c-order-overview__half">
                                        <div className="c-order-overview__products">

                                            {!!(this.props.mutation.list && Object.values(this.props.mutation.list).length) &&
                                            <>
                                                <header>
                                                    <h3>{Translate('Wijziging(en)', this.props.language.active)}</h3>
                                                </header>

                                                <div className="c-order-overview__products__container">
                                                    {Object.values(this.props.mutation.list).filter((mutation) => (!!mutation.changeLabels && Object.values(mutation.changeLabels).length)).map((mutation) => {

                                                        const {label, changeLabels} = mutation

                                                        return (
                                                            <div className="c-order-overview__products__single"
                                                                 style={{display: 'block'}}>

                                                                <div className="c-order-mutations">
                                                                    <h5>{label}</h5>

                                                                    {changeLabels &&
                                                                    <ul>
                                                                        {Object.values(changeLabels).map((changeLabel) => {

                                                                            return (<li>{changeLabel}</li>)

                                                                        })}
                                                                    </ul>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>

                                                <div style={
                                                    {
                                                        borderTop: '1px solid rgb(222, 229, 240)',
                                                        margin:
                                                            '2rem 0px'
                                                    }
                                                }
                                                />

                                            </>
                                            }

                                            <header>
                                                <h3>{Translate('Uw fietsen & accessoires', this.props.language.active)}</h3>
                                            </header>

                                            <div className="c-order-overview__products__container">
                                                {rentalItems && rentalItems.map((item) => {
                                                    if (item.type === 'category') {
                                                        const categoryData = item.category
                                                        return (
                                                            <div key={'rental-item-' + item.id}
                                                                 className="c-order-overview__products__single">
                                                                <div
                                                                    className="c-order-overview__products__single__container">
                                                                    <div
                                                                        className="c-order-overview__products__single__media">
                                                                        <img src={categoryData.image} alt=""/>
                                                                    </div>
                                                                    <span
                                                                        className="c-order-overview__products__single__amount">{item.quantity}x</span>
                                                                    <span
                                                                        className="c-order-overview__products__single__description">{DisplayItemName(categoryData)}</span>
                                                                </div>
                                                                <span
                                                                    className="c-order-overview__products__single__price">€ {DisplayPrice(item.totalPrice)}</span>

                                                            </div>
                                                        )
                                                    }


                                                    if (item.type === 'insurance') {


                                                        return (
                                                            <div key={'rental-item-' + item.id}
                                                                 className="c-order-overview__products__single">
                                                                <div
                                                                    className="c-order-overview__products__single__container">

                                                                    <span
                                                                        className="c-order-overview__products__single__amount">{item.quantity}x</span>
                                                                    <span
                                                                        className="c-order-overview__products__single__description">{item.description}</span>
                                                                </div>
                                                                <span
                                                                    className="c-order-overview__products__single__price">€ {DisplayPrice(item.totalPrice)}</span>

                                                            </div>
                                                        )
                                                    }

                                                    return null
                                                })
                                                }
                                                <div style={{
                                                    borderTop: '1px solid rgb(222, 229, 240)',
                                                    margin: '2rem 0px'
                                                }}/>
                                                {!!totalDeposit &&
                                                <div className="c-order-overview__total">
                                                    <span>{Translate('Totaalprijs borg', this.props.language.active)}</span><span>€ {DisplayPrice(totalDeposit)}</span>
                                                </div>
                                                }
                                                <div className="c-order-overview__total" style={{paddingTop: '0'}}>
                                                    <span>{Translate('Totaalbedrag (incl. 21% BTW)', this.props.language.active)}</span><span>€ {DisplayPrice(totalPrice)}</span>
                                                </div>
                                                <div className="c-order-overview__total" style={{paddingTop: '0'}}>
                                                    <span>{Translate('Openstaand', this.props.language.active)}</span><span>€ {DisplayPrice(totalPriceRest)}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="c-order-overview__payment-button">
                                            <button className="btn btn--rounded"
                                                    style={{
                                                        background: primary_color_light
                                                    }}
                                                    disabled={this.props.payment.processing}
                                                    onClick={() => this.props.dispatch(PaymentAction.pay(deposit))}
                                            >
                                                <span
                                                    style={{color: GetContrast(primary_color_light)}}>{Translate('Verder met betalen', this.props.language.active)}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            )
        } else return null
    }

}

export default connect(mapStateToProps)(Payment);
