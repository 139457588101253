import ActionUtility from '../../helpers/ActionUtility'
import ApiEnum from "../../constants/ApiEnum";
import MutationEffect from "./MutationEffect";

export default class MutationAction {
    static API_GET_LIST = 'MutationAction.API_GET_LIST';

    static apiGetList(uuid) {
        return async (dispatch, getState) => {

            const language = getState().language.active

            const values = {
                fetchUrl: ApiEnum.ApiClientMutationList + '?rental=' + uuid + '&language=' + language,
                accessToken: getState().authentication.accessToken
            }

            await ActionUtility.createThunkEffect(dispatch, MutationAction.API_GET_LIST, MutationEffect.apiList, values);
        };
    }

}