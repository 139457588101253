import data from '../constants/stringTranslations'

const Translate = (key, language) => {

    let string = 'Nv: ' + key

    data.filter(translateString => translateString["key"] === key).forEach(translateString => {
        string = translateString[language]
    })

    return string

}

export default Translate