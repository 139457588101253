import React from 'react'
import {connect} from "react-redux";
import GetContrast from "../helpers/GetContrast";
import Translate from "../helpers/Translate";
import {Link} from "react-router-dom";


const mapStateToProps = (state) => ({
    payment: state.payment,
    rental: state.rental,
    mutation: state.mutation,
    language: state.language
});


class Result extends React.Component {


    render() {

        if (this.props.payment && this.props.rental.data && this.props.rental.data.items) {

            const {
                rental,
                customer,
                customer_private_person,
            } = this.props.rental.data.items


            let title = ''
            let content = ''
            let showButton = false

            switch (this.props.payment.status) {
                case('paid'):
                    title = Translate('Betaling voldaan!', this.props.language.active)
                    content = Translate('Bedankt, uw betaling is in goede orde ontvangen', this.props.language.active)
                    break;
                case('failed'):
                    showButton = true
                    title = Translate('Betaling mislukt', this.props.language.active)
                    content = Translate('U heeft recentlijk uw openstaande betaling proberen te betalen. Dit is om onbekende reden mislukt. Probeer het a.u.b. opnieuw.', this.props.language.active)
                    break;
                case('canceled'):
                    showButton = true
                    title = Translate('Betaling geannuleerd', this.props.language.active)
                    content = Translate('U heeft de betaling geannuleerd. Probeer het a.u.b. opnieuw.', this.props.language.active)
                    break;
                case('expired'):
                    showButton = true
                    title = Translate('Betaling verlopen', this.props.language.active)
                    content = Translate('De betaling is verlopen. Probeer het a.u.b. opnieuw.', this.props.language.active)
                    break;
                default:
                    showButton = true
                    title = Translate('Betaling niet afgerond', this.props.language.active)
                    content = Translate('U heeft recentlijk uw openstaande betaling proberen te betalen. Deze betaling is niet afgerond. Probeer het a.u.b. opnieuw.', this.props.language.active)
                    break;
            }

            const {primary_color_dark, primary_color_light} = this.props.payment.data.items.transaction.partner

            return (
                <div className="App">

                    {!!rental &&
                    <section className={'c-banner-payment '} style={{
                        padding: '5rem 2.5rem',
                        background: primary_color_dark,
                        color: GetContrast(primary_color_dark)
                    }}>
                        <div className={'c-banner-payment__content o-container  o-container--secondary'}>
                            <h1 style={{marginBottom: '0'}}>{title}</h1>
                            <p style={{marginTop: '0'}}>{Translate('Bestelling', this.props.language.active)} {rental.id} -

                                {customer.type === 'private' &&
                                <> {customer_private_person.initials} {customer_private_person.first_name} {customer_private_person.insertion} {customer_private_person.last_name}</>
                                }
                            </p>
                        </div>
                    </section>
                    }

                    <main className="s-webshop-order language-nl">
                        <div className="o-container o-container--secondary">
                            <div className="">
                                <header className="c-subject__header">
                                    <p><span>{content}</span></p>
                                    {showButton &&
                                    <Link style={{
                                        background: primary_color_light,
                                        color: GetContrast(primary_color_light)
                                    }} className={'c-return-button'}
                                          to={'/' + this.props.rental.data.items.rental.uuid}>{Translate('Klik hier om de betaling af te ronden', this.props.language.active)}</Link>
                                    }
                                </header>
                            </div>
                        </div>
                    </main>
                </div>
            )
        } else return null
    }

}

export default connect(mapStateToProps)(Result);
